import federate, { App, Module } from '@ally/federator'
import {
  HostData,
  ActionBar,
  Session,
  Analytics,
  HostServices,
  BrowserHistory,
  AutoLoginService,
  HeaderService,
  SubNavSchema,
  FederationStatsService,
} from '@ally-financial/next-core'
import { attempt } from '@ally/utilitarian'

import log from '../whisper'
import { documentTitle, env, externals } from '../constants'
import { BootstrapServices } from '../providers'
import { UserRegistrationStates } from '../hooks/use-register-user'
import { track, trackRemoteAppUsage } from '../tracking'
import { FeatureFlagClients } from '../providers/bootstrap/tasks/features'
import { hasKey } from '../utils/object'
import { Schema } from './subnav'

export const registry: AppDetails[] = []

interface HostServicesInput {
  boot: BootstrapServices
  done: () => void
  session: Session
  actionBar: ActionBar
  logger: HostServices['log']
  hostData: HostData
  analytics: Analytics
  userRegistrationStates: UserRegistrationStates
  browserHistory: BrowserHistory
  autoLogin: AutoLoginService
  header: HeaderService
  federationStats: FederationStatsService
}

export type HostServicesMessage = HostServices & Pick<HostServicesInput, 'done'>

export const unknownApp = (): AppDetails => ({
  name: 'unknown',
  branch: 'unknown',
  commit: 'unknown',
  source: 'unknown',
  release: 'unknown',
  timestamp: 0,
})

/**
 * Gets the build details that we're bundled in this application using the
 * webpack DefinePlugin.
 */
export function getHostAppDetails(): AppDetails {
  if (!APP_BUILD_INFO) throw new Error('Missing host app details...')
  return APP_BUILD_INFO
}

/**
 * Registers an application.
 * When a module is loaded, the federator calls this hook. We're using it to
 * grab the build `info` that's passed by each remote to populate the debug
 * panel and track which versions of remotes have been loaded.
 */
export function onModuleLoaded({ source, exports: exported }: Module): void {
  attempt(() => {
    const info = exported.info || unknownApp()
    const { name, release } = info

    trackRemoteAppUsage(info)

    log.info({
      message: [`[REMOTES] Loaded: ${name}@${release}`],
    })

    registry.push({ ...info, source })
  })
}

/**
 * Creates a logger for the remote and assigns a `track` function to it.
 * This allows the remotes to use `logRocket.track` without requiring them to
 * import the logrocket package.
 */
export function getRemoteLogger(mounted: string): HostServices['log'] {
  return Object.assign(log.child({ nameSpace: mounted }), {
    track,
  })
}

export const RemoteStyles = new Set<HTMLLinkElement>()

/**
 * Removes ALL css <link /> elements appended to the DOM by remotes and stashes
 * them away. This will be called each time a remote unmounts.
 *
 * If the remote is re-mounted, they'll be re-injected into the DOM. This
 * ensures remote CSS doesn't conflict with one another.
 */
export function dropRemoteStyles(app?: App): void {
  const selector = app
    ? `link[data-remote-style="${app.id}]`
    : `link[data-remote-style]`
  const links = document.querySelectorAll<HTMLLinkElement>(selector)

  Array.from(links).forEach(e => {
    e.parentNode?.removeChild(e)
    RemoteStyles.add(e)
  })
}

/**
 * For CSS that's been added by previously mounted remotes, inject the CSS
 * back into the DOM if the remote re-mounts.
 */
export function injectRemoteStyles(app: App): void {
  RemoteStyles.forEach(style => {
    if (style.getAttribute('data-remote-style') === app.id) {
      document.head.appendChild(style)
    }
  })
}

export const resetDocumentTitle = (): string => (document.title = documentTitle)

/**
 * Takes in feature flag (LaunchDarkly) clients and their user
 * registation dates and returns a single object that contains
 * both per line-of-business scope.
 */
function getFeatureFlags(
  featureFlags: FeatureFlagClients,
  loadStates: UserRegistrationStates['ld'],
): HostServices['featureFlags'] {
  const withLoadState = {
    auto: {
      ...featureFlags.auto.client,
      loadState: loadStates.auto,
    },
    bank: {
      ...featureFlags.bank.client,
      loadState: loadStates.bank,
    },
    routing: {
      ...featureFlags.routing.client,
      loadState: loadStates.routing,
    },
  }

  // This proxy will make it so that if a user tries to access client methods
  // at the root level of the object it will attempt to proxy any properties
  // that do not exist there to it's child 'bank' property.
  // This is done for backwards-compatability of HostServices' 'featureFlags'
  // property and should be removed in the future after proper deprecation.
  // See: ANT-761
  const proxy = new Proxy(withLoadState, {
    get(target: typeof withLoadState, prop: string): any {
      if (hasKey(target, prop)) return target[prop]
      if (hasKey(target.bank, prop)) return target.bank[prop]
      return undefined
    },
  }) as HostServices['featureFlags']

  return proxy
}

/**
 * Gets the "HostServices" object to be sent to the remote app.
 * Abstracted here to make adding/removing items easier.
 */
export function getHostServices({
  done,
  boot,
  actionBar,
  session,
  logger,
  hostData,
  analytics,
  userRegistrationStates,
  browserHistory,
  autoLogin,
  header,
  federationStats,
}: HostServicesInput): HostServicesMessage {
  const featureFlags = getFeatureFlags(
    boot.featureFlags,
    userRegistrationStates.ld,
  )

  // adapter function for Utils openHelpModal, which calls setHelpModalState to:
  // set status Open
  // set selectedCategory
  const openHelpModalFromHeader = (categoryId?: string | number): void => {
    header.setHelpModalDefaultCategory(categoryId?.toString() || null)
    header.openHelpModal()
  }

  const setHelpModalStateFromHeader = (options: {
    status: 'Open' | 'Closed'
    selectedCategory: string | null | number
  }): void => {
    header.setHelpModalDefaultCategory(
      options.selectedCategory?.toString() || null,
    )
    if (options.status === 'Open') {
      header.openHelpModal()
    } else {
      header.closeHelpModal()
    }
  }

  const setSubNavFromHeader = (
    schema: SubNavSchema | ((prev: SubNavSchema) => SubNavSchema),
  ): void => {
    if (typeof schema !== 'function') {
      header.setSubNav(schema)
      return
    }
    const newSchema = schema(header.getState().subnav)
    header.setSubNav(newSchema)
  }

  const setIsHiddenFromHeader = (
    isHidden: boolean | ((prev: boolean) => boolean),
  ): void => {
    const current = header.getState().mode === 'MINIMAL'
    const next = typeof isHidden === 'function' ? isHidden(current) : isHidden
    if (next) {
      header.setMode('MINIMAL')
    } else {
      header.setMode('FULL')
    }
  }

  return {
    env,
    log: logger,
    done,
    query: boot.query,
    subnav: {
      set: setSubNavFromHeader,
      schema: header.getState().subnav,
      Schema,
    },
    actionBar,
    globalnav: {
      isHidden: header.getState().mode === 'MINIMAL',
      setIsHidden: setIsHiddenFromHeader,
    },
    utils: {
      helpModalState: {
        status: header.getState().helpModalStatus,
        selectedCategory: header.getState().helpModalDefaultCategory,
      },
      setHelpModalState: setHelpModalStateFromHeader,
      openHelpModal: openHelpModalFromHeader,
    },
    session,
    hostAppInfo: APP_BUILD_INFO || {},
    hostData,
    analytics,
    featureFlags,
    federationStats,
    browserHistory,
    autoLogin,
    header,
  }
}

export const headerFederator = federate({
  log,
  externals,
  onModuleLoaded,
})

export const contentFederator = federate({
  log,
  externals,
  onModuleLoaded,
})

export const debugFederator = federate({
  log,
  externals,
  onModuleLoaded,
})
