/**
 * Please list all feature flags here in this enum alphabetically.
 * Makes refactoring/removing flags easier.
 */
export enum FeatureFlag {
  CPRAEnabled = 'FF_CPRA',
  CreditCardEnabled = 'FF_credit-card-snapshot',
  CustomizationEnabled = 'FF_snapshot-customization',
  KeyCodesEnabled = 'FF_ally-next-key-codes',
  FederatedDebugEnabled = 'FF_ally-next-federated-debug',
}
