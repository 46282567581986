import {
  AutoLoginService,
  FederationStatsService,
  HeaderService,
} from '@ally-financial/next-core'
import React, { createContext, FC, useContext, useRef } from 'react'
import { useSession$ } from '../providers/session/useSession$'
import getAutoLoginService from './autoLogin'
import getHeaderService from './header'
import getFederationStats from './federationStats'

export type Services = {
  autoLogin: AutoLoginService
  header: HeaderService
  federationStats: FederationStatsService
}

const ServicesContext = createContext<Services>({} as any)

export const useServices = (): Services => useContext(ServicesContext)

export const ServicesProvider: FC = ({ children }) => {
  const session$ = useSession$()

  const { current: autoLogin } = useRef(getAutoLoginService({ session$ }))
  const { current: header } = useRef(getHeaderService())
  const { current: federationStats } = useRef(getFederationStats())

  return (
    <ServicesContext.Provider value={{ autoLogin, header, federationStats }}>
      {children}
    </ServicesContext.Provider>
  )
}
