import React, { FC } from 'react'
import styled from 'styled-components'
import { FederatedContent } from './FederatedContent'
import { FederatedHeader } from './FederatedHeader'
import { useRemotes } from './use-remotes'
import { DevRouteAwareness, MRGatewayNotification } from '../components'
import {
  FeatureFlag,
  useEmberPrefetch,
  useFeatureFlag,
  useIsWebWrapped,
  useRegisterUser,
} from '../hooks'
import { isAuthenticated, useSession } from '../providers'
import { FederatedDebug } from './FederatedDebug'
import { env } from '../constants'

/**
 * NOTE: min-height changes based on whether or not the SubNav is shown. That
 * is, when the user is authenticated vs. unauthenticated.
 */
const MainContent = styled.main(
  ({ $hasAuth }: { $hasAuth: boolean }) => `
    flex: 0;
    margin: auto;
    width: 100%;
    flex: 1;
    min-height: calc(100vh - ${$hasAuth ? 265 : 225}px);
  `,
)

export const FederatedApp: FC = () => {
  // This needs to be hoisted into a single location so that
  // the user does not get registered multiple times
  const userRegistrationStates = useRegisterUser()
  const allRemotes = useRemotes()

  useEmberPrefetch()

  const isWebWrapped = useIsWebWrapped()

  const federatedDebugEnabled = useFeatureFlag(
    FeatureFlag.FederatedDebugEnabled,
    false,
  )

  const debugEnabledDefault = env.isProd ? 'false' : 'true'
  const debugEnabledValue =
    sessionStorage.getItem('ENABLE_DEBUG_TOOLS') ?? debugEnabledDefault
  const isFederatedDebugEnabled =
    federatedDebugEnabled && debugEnabledValue === 'true'

  return (
    <>
      {!isWebWrapped && (
        <FederatedHeader userRegistrationStates={userRegistrationStates} />
      )}

      <MainContent
        id="main"
        $hasAuth={isAuthenticated(useSession())}
        tabIndex={-1}
      >
        <DevRouteAwareness remotes={allRemotes} isLoading={false} />
        <MRGatewayNotification />
        <FederatedContent userRegistrationStates={userRegistrationStates} />
      </MainContent>

      {isFederatedDebugEnabled && (
        <FederatedDebug userRegistrationStates={userRegistrationStates} />
      )}
    </>
  )
}
