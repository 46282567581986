import { useEffect, useMemo, useState } from 'react'
import { Observable } from 'rxjs'

export type HostService<T> = {
  state$: Observable<T>
  getState(): T
}

export type GetHostServiceType<
  T extends HostService<any>
> = T extends HostService<infer U> ? U : never

export type ReactHostService<T extends HostService<any>> = {
  state: GetHostServiceType<T>
} & Omit<T, 'getState' | 'state$'>

/**
 * Takes in a "Host Service" instance and returns the same properties
 * in a React compatible context. Most importantly it replaces the
 * observable-based 'state$' and 'getState()' with a single 'state'
 * property that contains the current state value.
 *
 * In the future this will be moved to @ally-financial/next-react.
 */
export function useService<T extends HostService<any>>(
  service: T,
): ReactHostService<T> {
  const [state, setState] = useState<T>(service.getState())

  useEffect(() => {
    const sub = service.state$.subscribe(newState => setState(newState))
    return (): void => sub.unsubscribe()
  }, [service])

  const rest = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { state$, getState, ...others } = service
    return others
  }, [service])

  const value = useMemo(
    () =>
      ({
        ...rest,
        state,
      } as ReactHostService<T>),
    [rest, state],
  )

  return value
}
