import {
  SchemaClass,
  SubNavButtonSchema,
  SubNavDropdownSchema,
  SubNavLinkSchema,
  SubNavSchema,
} from '@ally-financial/next-core'

const sortSchema = (
  unsortedSchema: SubNavSchema,
  sortOrder: string[],
): SubNavSchema => {
  return [...unsortedSchema].sort((a, b) => {
    const aKeyIndex = sortOrder.indexOf(a.key)
    const bKeyIndex = sortOrder.indexOf(b.key)
    const isAMissing = aKeyIndex < 0
    const isBMissing = bKeyIndex < 0

    // if neither a nor b are in the sort array, don't sort them
    if (isAMissing && isBMissing) return 0
    // if a is missing from the sort array, but not b, sort a after b
    if (isAMissing) return 1
    // if a is present in the sort array, but b is missing, sort a before b
    if (isBMissing) return -1
    // if a comes before b in the sort array, sort it before b
    if (aKeyIndex < bKeyIndex) return -1
    // if a comes after in the sort array, sort it after b
    if (aKeyIndex > bKeyIndex) return 1
    // if none of the above conditions return true, don't sort them
    return 0
  })
}

type SchemaItem = SubNavLinkSchema | SubNavButtonSchema | SubNavDropdownSchema

export const Schema: SchemaClass = class Schema {
  constructor(schema: SubNavSchema, sortOrder?: string[]) {
    this.schema = schema
    this.sortOrder = sortOrder || []
  }

  schema: SubNavSchema

  sortOrder: string[]

  set(schema: SubNavSchema): Schema {
    this.schema = sortSchema(schema, this.sortOrder)
    return this
  }

  add(partialSchema: SubNavSchema): Schema {
    const matches: number[] = []
    const oldPartial = this.schema.map(
      (item: SchemaItem): SchemaItem => {
        const match = partialSchema.findIndex(it => it.key === item.key)
        if (match < 0) return item
        matches.push(match)
        return partialSchema[match]
      },
    )
    const newFilteredPartial = partialSchema.filter(
      (item: SchemaItem, index: number): boolean => {
        const isAlreadyIncluded = matches.includes(index)
        return !isAlreadyIncluded
      },
    )
    const newCombined = [...oldPartial, ...newFilteredPartial]

    this.schema = sortSchema(newCombined, this.sortOrder)
    return this
  }

  remove(itemsToRemove: string[]): Schema {
    this.schema = this.schema.reduce(
      (acc: SubNavSchema, item): SubNavSchema => {
        if (itemsToRemove.includes(item.key)) return acc
        return [...acc, item]
      },
      [],
    )
    return this
  }

  reset(): Schema {
    this.schema = []
    return this
  }
}
