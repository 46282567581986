import { hot } from 'react-hot-loader/root'

import React, { useEffect } from 'react'

import { BrowserRouter } from 'react-router-dom'
import { FauxnamiProvider } from '@ally/fauxnami'
import { MetronomeProvider } from '@ally/metronome-ui'
import { DevicePrint } from '@ally/transmitigator'
import { AriaLiveProvider } from '@ally/use-aria-live'

import log from './whisper'

import { GlobalLayout } from './layout'
import { track, TrackingEvent } from './tracking'
import { env, keyCodes, keyCodesLSEnabled } from './constants'

import { FeatureFlag, useFeatureFlag } from './hooks'

import {
  Warpgate,
  DebugPanel,
  ScrollToTop,
  ErrorBoundary,
  SessionTimeout,
  FeatureFlagInfo,
  LivePerson,
  RemoveHash,
  CPRAPreference,
} from './components'

import {
  SessionProvider,
  HostDataProvider,
  AnalyticsProvider,
  BootstrapProvider,
  TransmitRefProvider,
} from './providers'
import { FederatedApp } from './federation'
import { useAppFocus } from './hooks/focus'
import { ServicesProvider } from './services/ServicesProvider'

/**
 * Checks if key codes are enabled and sets up the <FauxnamiProvider />.
 * Key codes are enabled when the `FF_ally-next-key-codes` flag is enabled and
 * the `ENABLE_KEY_CODES` local storage value is set.
 *
 * NOTE: The flag is a failsafe to turn it off if necessary.
 */
export const MaybeKeyCodes: React.FC = ({ children }) => {
  const keyCodesFlagEnabled = useFeatureFlag(
    FeatureFlag.KeyCodesEnabled,
    !env.isProd,
  )
  const keyCodesAreEnabled = keyCodesFlagEnabled && keyCodesLSEnabled

  useEffect(() => {
    log.info({
      message: `[KEYCODES] ${keyCodesAreEnabled ? 'enabled' : 'disabled'}`,
    })

    if (keyCodesAreEnabled) track(TrackingEvent.KeycodesEnabled)
  }, [keyCodesAreEnabled])

  return (
    <FauxnamiProvider enabled={keyCodesAreEnabled} keyCodes={keyCodes}>
      {children}
    </FauxnamiProvider>
  )
}

export const App: React.FC = () => {
  useAppFocus()

  return (
    <RemoveHash>
      <BrowserRouter>
        <MetronomeProvider>
          <AriaLiveProvider>
            <TransmitRefProvider>
              <DevicePrint track={track} />
              <SessionProvider>
                <Warpgate>
                  <BootstrapProvider>
                    <ServicesProvider>
                      <CPRAPreference />
                      <MaybeKeyCodes>
                        <HostDataProvider>
                          <LivePerson />
                          <AnalyticsProvider>
                            <GlobalLayout>
                              <ErrorBoundary>
                                <FederatedApp />
                              </ErrorBoundary>
                            </GlobalLayout>
                            <ScrollToTop />
                            <SessionTimeout />
                            <DebugPanel />
                            <FeatureFlagInfo />
                          </AnalyticsProvider>
                        </HostDataProvider>
                      </MaybeKeyCodes>
                    </ServicesProvider>
                  </BootstrapProvider>
                </Warpgate>
              </SessionProvider>
            </TransmitRefProvider>
          </AriaLiveProvider>
        </MetronomeProvider>
      </BrowserRouter>
    </RemoveHash>
  )
}

App.displayName = 'HostApp(ally-online-services)'
export default process.env.NODE_ENV === 'development' ? hot(App) : App
