import silence from '@ally/silence'
import log from '../../../whisper'

import * as constants from '../../../constants'

const { logScrubbingEnabled } = constants
const { release, timestamp } = APP_BUILD_INFO

const message = `

██████╗ ██████╗   Loaded...
██╔══██╗╚════██╗  ${new Date(timestamp).toLocaleDateString()}
██║  ██║ █████╔╝  ${release}
██║  ██║ ╚═══██╗
██████╔╝██████╔╝
╚═════╝ ╚═════╝
`

log.info({
  color: '#4d83a3',
  message,
  meta: {
    styles: `
      font-family: Menlo, Monaco, sans-serif;
    `,
  },
})

// A cheap mechanism to expose constants for debugging.
// console.log(window[Symbol.for('@@ally/constants')]) to dump to console.
;(window as any)[Symbol.for('@@ally/constants')] = {
  ...constants,
  APP_BUILD_INFO,
}

// Global Setup Tasks
// Tasks called in the global scope that will run before any rendering, etc.
if (logScrubbingEnabled) silence()
