import React, { FC, useMemo, useState, useEffect } from 'react'
import { Redirect } from 'react-router'

import { useGlobalLocation } from '@ally/federator'

import { Domains } from '@ally-financial/next-core'
import log from '../../whisper'
import { useSession, useHostData, SessionContextValue } from '../../providers'
import {
  SDE,
  LivePerson,
  LPIdentity,
  LPTokenCallback,
  LPGetJwt,
  LPIdentityFunction,
  LPIdentityCallbackFunction,
} from '../../global-types'

import {
  SetRedirect,
  assembleSDEs,
  delayUntilLivePersonReady,
  getIdentity,
  chatPII,
  isSharedPage,
} from './utils'
import { useLobs } from '../../hooks'
import { useAwaitSession } from './useAwaitSession'

interface RefreshChatToken {
  session: SessionContextValue
}

/**
 * 1. Return the legacyChatToken from sessionData
 * 2. If token is expired, call transmit legacySession journey to get new token.
 */
export async function fetchChatToken({
  session,
}: RefreshChatToken): Promise<string> {
  const { data: sessionData, fetchLegacySession } = session
  if (!sessionData) return ''

  const timeNow = new Date().getTime()
  const chatTokenExpiry = sessionData.chatTokenExpiry as number

  if (chatTokenExpiry > timeNow) return sessionData.legacyChatToken as string

  const data = await fetchLegacySession('chat_only')
  return data.legacyChatToken
}

interface TokenCallbackProps {
  sessionPromise: Promise<SessionContextValue>
}

/**
 * Returns a function that will be set to window scope as `window.lpGetJWT`
 * This function will be called by LP when chat window opens.
 * 1. If authenticated - pass chatToken to callback.
 * 2. If unauthenticated - store the callback function in react state
 *    and call it when chatToken is available.
 */
function setChatToken({ sessionPromise }: TokenCallbackProps): LPGetJwt {
  return async (callback: LPTokenCallback): Promise<void> => {
    const session = await sessionPromise

    const chatToken = await fetchChatToken({ session })

    log.info({
      message: ['LivePerson window.lpGetJWT invoked:', { chatToken }],
    })

    callback(chatToken)
  }
}

/**
 * Returns a function that will be passed to liveperson identities array
 */
export function getIdentityCallback(
  identity: LPIdentity | null,
  storeIdentityCallback: (f: LPIdentityCallbackFunction) => void,
): LPIdentityFunction {
  return (callback: LPIdentityCallbackFunction): void => {
    log.info({
      message: ['LivePerson identities invoked:', { identity }],
    })
    if (identity) {
      callback(identity)
    } else {
      storeIdentityCallback(() => callback)
    }
  }
}

function addPrivateAttributes(lp: LivePerson): void {
  // Add private data attributes
  try {
    // If the engagement window is (re)opened, ensure that has private data attributes set.
    lp.events.bind({
      eventName: 'conversationInfo',
      appName: 'lpUnifiedWindow',
      func: chatPII,
      async: true,
      triggerOnce: false,
    })
  } catch (err) {
    log.error({
      message: ['LivePerson chatPII failed to run:', err],
    })
  }
}

/**
 * Returns whether we have all the data we need to initialize chat.
 * - Authentication Information
 * - Account Information (for Auto users)
 * - Customer Information (for Auto users)
 */
function useChatDataReady(): boolean {
  // Determine if we are ready to initialize LivePerson
  // This is a temporary step to prevent the race condition caused
  // between LP init and the user information we need. Will be
  // removed in later refactor
  const session = useSession()
  const userRoles = useLobs()
  const isAutoUser = userRoles.auto
  const hostData = useHostData()
  const accountDataLoaded = hostData[Domains.AFG_ACCOUNTS].data != null
  const customerDataLoaded = hostData[Domains.AFG_CUSTOMER].data != null

  const isAuthenticated =
    session.status === 'Authenticated' && !!session.data?.legacyChatToken
  // If a user has an Auto relationship, we need to wait for Accounts and Customer
  // data to be able to populate the required SDEs
  const waitingForData =
    isAutoUser && (!accountDataLoaded || !customerDataLoaded)

  return isAuthenticated && !waitingForData
}

/**
 * Sets identities callback, called with every route change
 */
function useLivePersonIdentity(
  identity: LPIdentity | null,
  hasChatToken: boolean,
  updateIdentityCallback: (f: LPIdentityCallbackFunction) => void,
): void {
  const location = useGlobalLocation()
  useMemo(() => {
    window.lpTag = window.lpTag || {}
    const lp = window.lpTag
    lp.autoStart = false
    const callback = getIdentityCallback(identity, updateIdentityCallback)
    lp.identities = []
    lp.identities.push(callback)
    addPrivateAttributes(lp)
  }, [location, hasChatToken, identity])
}

/**
 * Invokes window.lpTag.newPage with the current window location.
 */
function invokeLivePersonNewPage(lp: LivePerson, sdes: SDE[]): void {
  const { href } = window.location

  const hasChatNode = document.getElementById('chatlinktop')?.hasChildNodes()
  const divIdsToKeep = hasChatNode ? { chatlinktop: true } : undefined
  const section = isSharedPage(window.location) ? ['shared'] : ['authbank']

  log.info({
    message: ['LivePerson Page Call:', { href, section, sdes }],
  })

  lp.newPage(href, {
    sdes,
    section,
    taglets: {
      rendererStub: {
        divIdsToKeep,
      },
    },
  })
}

/**
 * Watches for updates to the window location and SDE configuration and:
 *
 * - Waits for window.lpTag to be available.
 *   If not available in a reasonable amount of time, log an error.
 * - Call window.lpTag.newPage with the current page location and SDE set.
 * - If the SDEs or location changes, repeat.
 */
function useLivePersonPage(setRedirect: SetRedirect): void {
  const location = useGlobalLocation()
  const session = useSession()
  const hostData = useHostData()

  const hasChatToken = !!session.data?.legacyChatToken

  // Assemble SDEs when ready
  const isReady = useChatDataReady()
  const sdes = useMemo(
    () => (isReady ? assembleSDEs(session, hostData) : null),
    [isReady],
  )

  useEffect(() => {
    delayUntilLivePersonReady(setRedirect)
      .then(lp => {
        if (sdes) {
          invokeLivePersonNewPage(lp, sdes)
        }
      })
      .catch(e => {
        log.error({
          message: ['LivePerson tag could not be inserted:', e.stack],
        })
      })
  }, [sdes, location, setRedirect, hasChatToken])
}

/**
 * The LivePerson component.
 *
 * - Waits for the window.lpTag library to become available for 30 seconds.
 * - When available, calls window.lpTag.newPage with the current SDE set and
 *   location. If not, a warning is logged.
 *
 * LivePerson document for Web Messaging integration
 * https://developers.liveperson.com/consumer-authentication-detailed-api.html
 */
export const LivePersonManager: FC = () => {
  const [redirect, setRedirect] = useState<string>('')
  const [
    identityCallback,
    storeIdentityCallback,
  ] = useState<LPIdentityCallbackFunction>()

  const session = useSession()
  const { data: sessionData } = session
  // Use aaosId as a fallback to GUID - auto only users will not have a GUID
  // Using || instead of ?? here because GUID can be an empty string
  const chatId = sessionData?.guid || sessionData?.aaosId
  const hasChatToken = !!sessionData?.legacyChatToken

  const identity = useMemo(() => getIdentity(chatId), [chatId])

  // Adds identities callback
  useLivePersonIdentity(identity, hasChatToken, storeIdentityCallback)

  // Call liveperson new page
  useLivePersonPage(setRedirect)

  // This is a workaround implemented to get web messaging working with storefront redirection
  useEffect(() => {
    if (identity) {
      log.info({
        message: ['LivePerson lpTag.start() invoked:'],
      })
      window.lpTag?.start?.()
    }
  }, [identity, hasChatToken])

  // Handles stored identities callback.
  // Once identities are available pass it to callback
  useEffect(() => {
    if (identityCallback && identity) {
      log.info({
        message: ['LivePerson stored identity callback invoked:', { identity }],
      })
      identityCallback(identity)
      storeIdentityCallback(undefined)
    }
  }, [identity, identityCallback])

  return <>{redirect && <Redirect to={redirect} />}</>
}

const AuthenticatedLivePerson: FC = () => {
  const session = useSession()
  const sessionPromise = useAwaitSession(session)

  // Adds a window method to pass id_token to livePerson
  // (method name deviates from LP documentation as agreed upon by different teams)
  window.lpGetJWT = useMemo(() => setChatToken({ sessionPromise }), [
    sessionPromise,
  ])

  const isReady = useChatDataReady()

  if (!isReady) return null

  return <LivePersonManager />
}

export default AuthenticatedLivePerson
