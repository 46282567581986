import initializeLaunchDarkly, { AllyLaunchDarkly } from '@ally/aardvarkly'
import { FeatureFlagClientName } from '@ally-financial/next-core'
import { memoize } from '@ally/utilitarian'

import log from '../../../whisper'

import {
  TrackingEvent,
  TrackingEvents,
  withTrackingEventsAsync,
} from '../../../tracking'

import {
  env,
  launchDarklyAutoEnvKey,
  launchDarklyBankEnvKey,
  launchDarklyRoutingEnvKey,
} from '../../../constants'

import getLogRocket from '../../../services/logRocket/service'

export type FeatureFlagClients = Record<FeatureFlagClientName, AllyLaunchDarkly>

const isOverridden = env.override

const hostOverride = env.pick({
  dev: 'secure-dev.ally.com',
  qa1: 'secure-qa1.ally.com',
  qa2: 'secure-qa2.ally.com',
  qa3: 'secure-qa3.ally.com',
  psp: 'secure-prodsupt.ally.com',
  prod: 'secure.ally.com',
})

export const getLDClientBankMemo = memoize(() =>
  initializeLaunchDarkly({
    key: launchDarklyBankEnvKey,
    platform: 'react',
    host: isOverridden ? hostOverride : undefined,
  }),
)

export const getLDClientRoutingMemo = memoize(() =>
  initializeLaunchDarkly({
    key: launchDarklyRoutingEnvKey,
    platform: 'react',
    ldConfig: { sendEventsOnlyForVariation: false, evaluationReasons: true },
    host: isOverridden ? hostOverride : undefined,
  }),
)

export const getLDClientAutoMemo = memoize(() =>
  initializeLaunchDarkly({
    key: launchDarklyAutoEnvKey,
    platform: 'react',
    host: isOverridden ? hostOverride : undefined,
  }),
)

const events: Record<FeatureFlagClientName, TrackingEvents> = {
  auto: [TrackingEvent.LDAutoInit, TrackingEvent.LDAutoDone],
  bank: [TrackingEvent.LDBankInit, TrackingEvent.LDBankDone],
  routing: [TrackingEvent.LDRoutingInit, TrackingEvent.LDRoutingDone],
}

/**
 * Loads the feature flag (LaunchDarkly) instances for:
 *
 *   1. Routing: Used to generate the federated route schema.
 *   2. Bank: Used by the "Bank" experiences.
 *
 * Note, if a client fails to initialize, the @ally/aardvarkly package will log
 * an error and initialization will fail gracefully.
 */
export default async (): Promise<FeatureFlagClients> => {
  const auto = getLDClientAutoMemo()
  const bank = getLDClientBankMemo()
  const routing = getLDClientRoutingMemo()

  await Promise.all([
    withTrackingEventsAsync(auto.getInitPromise, events.auto)(),
    withTrackingEventsAsync(bank.getInitPromise, events.bank)(),
    withTrackingEventsAsync(routing.getInitPromise, events.routing)(),
  ])

  // If FF_allowlist-config contains a "source" property, it fetches the config from that URL
  // Else, it passes the flag as is, returning the "allowList" property
  const useAllowList = bank.client.variation('FF_ally-next-allowlist', false)
  if (useAllowList) {
    const masqueradeConfig = bank.client.variation('FF_allowlist-config', {})
    const { source } = masqueradeConfig
    let payload
    if (source) {
      try {
        const response = await fetch(source)
        payload = await response.json()
      } catch (err) {
        payload = {}
        log.error({
          // masqueradeConfig will not match any allowList items, effectively maskAll
          message: ['[UIDM] Failed to parse response:', err],
        })
      }
    }

    getLogRocket().setMasqueradeConfig(payload || masqueradeConfig)
  }

  return {
    auto,
    bank,
    routing,
  }
}
