import React from 'react'
import { AutoLoginService } from '@ally-financial/next-core'
import { flow } from '@ally/utilitarian'

import {
  Box,
  Space,
  Modal,
  Button,
  ButtonGroup,
  TextBody,
  TextHeading,
} from '@ally/metronome-ui'

import { SessionContextValue } from '../../providers'
import { getLogout } from '../../utils/logout'

interface SessionTimeoutModalProps {
  restart: VoidFunction
  session: SessionContextValue
  autoLogin: AutoLoginService
  open: boolean
  setOpen: (v: boolean) => void
}

interface ModalContentProps {
  handleSessionLogout: VoidFunction
  handleSessionExtension: VoidFunction
}

/**
 * A modal that is displayed when the user's session is about to expire.
 * Provides them the options of extending their session or logging out.
 * Note, after the session expires, the modal will be automatically dismissed
 * and the user will be logged out.
 */
export const ModalContent: React.FC<ModalContentProps> = ({
  handleSessionLogout,
  handleSessionExtension,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Space mb="sm">
        <TextHeading tag="h2" size="md" tabIndex={-1}>
          Are you still there?
        </TextHeading>
        <TextBody tag="p" size="sm">
          For your protection, we&apos;ll close your session due to inactivity.
        </TextBody>
      </Space>
      <ButtonGroup>
        <Button
          variant="primary"
          text="Continue My Session"
          onClick={handleSessionExtension}
          data-testid="session-timeout-extend-button"
        />
        <Button
          variant="link"
          text="Log Out"
          onClick={(): void => handleSessionLogout()}
          data-testid="session-timeout-logout-button"
        />
      </ButtonGroup>
    </Box>
  )
}

export const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({
  restart,
  session,
  autoLogin,
  open,
  setOpen,
}) => {
  const logout = getLogout(session, autoLogin)
  const handleSessionLogout = flow(logout)
  const handleSessionExtension = flow(restart, () => {
    setOpen(false)
  })

  const handleOnOpenChange = (newOpen: boolean): void => {
    if (!newOpen) handleSessionExtension()
  }

  return (
    <>
      <Modal
        content={
          <ModalContent
            handleSessionLogout={handleSessionLogout}
            handleSessionExtension={handleSessionExtension}
          />
        }
        open={open}
        onOpenChange={handleOnOpenChange}
      />
    </>
  )
}
