import React, { useEffect } from 'react'
import { Analytics } from '@ally-financial/next-core'
import cookies from 'js-cookie'
import {
  mountDigitalDataObject,
  trackEvent,
  trackImpression,
  trackPageView,
} from './utils'

export type AnalyticsContextValue = Analytics

export const ALLY_TM_SITE_HISTORY_COOKIE_NAME = 'allytmConfSiteHistory'

const initialValue: AnalyticsContextValue = {
  mountDigitalDataObject,
  trackEvent,
  trackPageView,
  trackImpression,
}

const AnalyticsContext = React.createContext<AnalyticsContextValue>(
  initialValue,
)

const AnalyticsConsumer = AnalyticsContext.Consumer

export const deleteHistoryCookie = (): void => {
  const tmHistoryCookie = cookies.get(ALLY_TM_SITE_HISTORY_COOKIE_NAME) || ''

  if (tmHistoryCookie.includes('allysf-login-v1-username')) {
    cookies.remove(ALLY_TM_SITE_HISTORY_COOKIE_NAME, {
      path: '/',
      domain: 'ally.com',
    })
  }
}

const AnalyticsProvider: React.FC = ({ children }) => {
  useEffect(() => {
    deleteHistoryCookie()
  }, [])

  return (
    <AnalyticsContext.Provider
      value={{
        ...initialValue,
        trackPageView,
        trackImpression,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}

export { AnalyticsContext, AnalyticsConsumer, AnalyticsProvider }
