import { Box, Link as MuiLink, Media, get, upToMd } from '@ally/metronome-ui'
import React from 'react'
import styled from 'styled-components'
import { env } from '../../constants'
import { FeatureFlag, useFeatureFlag } from '../../hooks'

const mdDownMediaQuery = get.media.down('md')

const LinksList = styled.ul`
  display: flex;
  padding-left: ${get.space('none')};

  ${mdDownMediaQuery} {
    width: 100%;
    align-items: center;
    margin-top: 0px;
    margin-bottom: ${get.space('md')};
  }
`

const LinksListItem = styled.li`
  list-style: none;

  padding: ${get.space('none')} ${get.space('xs')};
  &:first-child {
    padding-left: ${get.space('none')};
  }
  &:last-child {
    padding-right: ${get.space('none')};
  }

  ${mdDownMediaQuery} {
    text-align: center;
    flex: 1 1 0px;
  }
`

// Make links injected within the Qualtrics div look like metronome links
const QualtricsDiv = styled.div`
  & > a {
    display: inline;
    font-weight: ${get.fontWeights('bold')};
    cursor: pointer;
    border: none;
    text-decoration: none;
    font-size: ${get.fontSizes('sm')};
    padding: 0px;
    background-color: transparent;
    color: ${get.colors('link-blue')};
    white-space: normal;
    box-sizing: border-box;
  }
`

export const Links: React.FC = () => {
  const isCPRAEnabled = useFeatureFlag(FeatureFlag.CPRAEnabled, false)

  const storeFrontLink = (
    <MuiLink text="allybank.com">
      <a
        href="https://allybank.com/"
        target="_blank"
        rel="noreferrer"
        data-track-name="HomepageLink"
        data-testid="storefront-link"
      >
        content replaced by metronome
      </a>
    </MuiLink>
  )

  const communityLink = (
    <MuiLink text="Ally Community">
      <a
        href="http://community.ally.com/straight-talk/"
        target="_blank"
        rel="noreferrer"
        data-track-name="AllyCommunity"
        data-testid="community-link"
      >
        content replaced by metronome
      </a>
    </MuiLink>
  )

  const securityLink = (
    <MuiLink text="Security">
      <a
        href="https://www.ally.com/security"
        target="_blank"
        rel="noreferrer"
        data-track-name="Security"
        data-testid="security-link"
      >
        content replaced by metronome
      </a>
    </MuiLink>
  )

  const privacyLink = (
    <MuiLink text="Privacy">
      <a
        href="https://www.ally.com/privacy/"
        target="_blank"
        rel="noreferrer"
        data-track-name="Privacy"
        data-testid="privacy-link-mobile"
      >
        content replaced by metronome
      </a>
    </MuiLink>
  )

  const legalLink = (
    <MuiLink text="Legal">
      <a
        href="https://www.ally.com/legal/"
        target="_blank"
        rel="noreferrer"
        data-track-name="Legal"
        data-testid="legal-link"
      >
        content replaced by metronome
      </a>
    </MuiLink>
  )

  const cpraLink = (
    <MuiLink text="Targeted Advertising Preferences">
      <a
        href={
          env.isProd
            ? 'https://www.ally.com/privacy/advertising-preferences/'
            : 'https://staging.ally.com/privacy/advertising-preferences.html/?prtarget=am'
        }
        target="_blank"
        rel="noreferrer"
        data-track-name="TargetedAdvertisingPreferences"
        data-testid="advertising-preferences-link"
      >
        content replaced by metronome
      </a>
    </MuiLink>
  )

  return (
    <Box
      as="section"
      display="flex"
      justifyContent="center"
      paddingBottom={upToMd('0', '20px')}
    >
      <LinksList data-testid="footer-link-list">
        {/* Mobile Links */}
        <Media.MdDown>
          <LinksListItem>{storeFrontLink}</LinksListItem>
          <span aria-hidden="true">|</span>
          <LinksListItem>{privacyLink}</LinksListItem>
          {isCPRAEnabled && <span aria-hidden="true">|</span>}
          {isCPRAEnabled && <LinksListItem>{cpraLink}</LinksListItem>}
        </Media.MdDown>

        {/* Desktop Links */}
        <Media.LgUp>
          <LinksListItem data-testid="feedback">
            <QualtricsDiv id="qualtricsFeedback" />
          </LinksListItem>
          <LinksListItem>{communityLink}</LinksListItem>
          <LinksListItem>{securityLink}</LinksListItem>
          <LinksListItem>{privacyLink}</LinksListItem>
          <LinksListItem>{legalLink}</LinksListItem>
          {isCPRAEnabled && <LinksListItem>{cpraLink}</LinksListItem>}
        </Media.LgUp>
      </LinksList>
    </Box>
  )
}
