import { once } from '@ally/utilitarian'
import { BehaviorSubject, Observable } from 'rxjs'
import {
  HeaderState,
  RemoteConfig,
  SubNavSchema,
} from '@ally-financial/next-core'
import { toProxyObject } from '../toProxyObject'

export class HeaderService {
  private stateSubject: BehaviorSubject<HeaderState> = new BehaviorSubject<HeaderState>(
    {
      showFDICBanner: false,
      helpModalStatus: 'Closed',
      helpModalDefaultCategory: null,
      remoteNotifications: [],
      subnav: [],
      mode: 'FULL',
    },
  )

  readonly state$: Observable<HeaderState> = this.stateSubject.asObservable()

  getState(): HeaderState {
    // BehaviorSubject.getValue() does not return a copy of the values, but a direct reference.
    // Return a copy of the state object values to avoid accidental data mutations
    return { ...this.stateSubject.getValue() }
  }

  setShowFDICBanner(value: boolean): void {
    const currentState = this.getState()
    if (currentState.showFDICBanner === value) {
      return
    }
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      showFDICBanner: value,
    })
  }

  openHelpModal(): void {
    const currentState = this.getState()
    if (currentState.helpModalStatus === 'Open') {
      return
    }
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      helpModalStatus: 'Open',
    })
  }

  closeHelpModal(): void {
    const currentState = this.getState()
    if (currentState.helpModalStatus === 'Closed') {
      return
    }
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      helpModalStatus: 'Closed',
    })
  }

  setHelpModalDefaultCategory(categoryId: string | number | null): void {
    const currentState = this.getState()
    if (currentState.helpModalDefaultCategory === categoryId) {
      return
    }
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      helpModalDefaultCategory: categoryId,
    })
  }

  setRemoteNotifications(notifications: RemoteConfig[]): void {
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      remoteNotifications: notifications,
    })
  }

  setSubNav(subNav: SubNavSchema): void {
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      subnav: subNav,
    })
  }

  // FULL === isHidden = false
  // MINIMAL ==== isHidden = true
  setMode(modeArg: 'FULL' | 'MINIMAL'): void {
    const currentState = this.getState()
    if (currentState.mode === modeArg) {
      return
    }
    this.stateSubject.next({
      ...this.stateSubject.getValue(),
      mode: modeArg,
    })
  }
}

export default once(() => toProxyObject(new HeaderService()))
