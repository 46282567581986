import React, { useEffect } from 'react'

import { UserRegistrationStates } from '../hooks'
import { Route } from '../providers'
import { RemoteAppContainer } from './RemoteAppContainer'
import { useDebugFederation } from './use-federation'
import { debugFederator } from './utils'

export interface FederatedDebugProps {
  userRegistrationStates: UserRegistrationStates
}

export const isDebugRemote = ({ slot }: Route): boolean => slot === 'debug'

export const FederatedDebug: React.FC<FederatedDebugProps> = ({
  userRegistrationStates,
}) => {
  const {
    federatedRef,
    isFederatedRoute,
    isTransitioning,
  } = useDebugFederation({
    userRegistrationStates,
    remoteFilter: isDebugRemote,
  })

  const remoteLoaded = isFederatedRoute && !isTransitioning

  useEffect(() => {
    if (federatedRef.current) {
      // Including this here since we are attempting to initialize this instance of federator after we have already bootstrapped which causes issues when loading associated apps
      debugFederator.listen(federatedRef.current as HTMLDivElement)
    }
  }, [federatedRef.current])

  useEffect(() => {
    return (): void => {
      /**
       * Including this here because the FederatedDebug component can be mounted and unmounted on the same page without a route change.
       *
       * This overrides an issue in the debugFederator instance's internal state handling caused by @ally/federator where the remote app
       * is only considered "unmounted" after a route change NOT when the actual React component consuming it unmounts.
       *
       * This causes the remote to no longer be able to be mounted again on the same route after it was unmounted on that route.
       *
       * **/
      debugFederator.setApps({})
    }
  }, [])

  return (
    <RemoteAppContainer
      ref={federatedRef}
      isLoading={!remoteLoaded}
      data-testid="debug-remote-app-container"
    />
  )
}
