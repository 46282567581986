import { AutoProfile, Session } from '@ally-financial/next-core'
import {
  KEEP_ALIVE_ENDPOINT,
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  SHARED_API_HEADERS,
} from './constants'

export const hasAutoProfileId = (transmitSession: Session | null): boolean =>
  !!transmitSession?.data?.aaosId

export const hasAutoRelationship = (transmitSession: Session | null): boolean =>
  !!transmitSession?.data?.allyUserRole.auto

export async function autoKeepAlive(args: {
  accessToken: string
  csrfToken: string
}): Promise<void> {
  const { accessToken, csrfToken } = args
  const response = await fetch(KEEP_ALIVE_ENDPOINT, {
    method: 'POST',
    headers: {
      ...SHARED_API_HEADERS,
      Authorization: `Bearer ${accessToken}`,
      csrftoken: csrfToken,
    },
  })

  if (!response.ok) throw new Error('KEEP_ALIVE_API_ERROR')
}

export async function autoKeepAliveWithTransmit(
  transmitSession: Session | null,
  profile: AutoProfile | null,
): Promise<void> {
  // Transmit Checks
  if (
    transmitSession === null ||
    transmitSession.status !== 'Authenticated' ||
    transmitSession.data === null
  )
    throw new Error('NOT_TRANSMIT_AUTHENTICATED')
  if (!hasAutoRelationship(transmitSession))
    throw new Error('NO_AUTO_RELATIONSHIP')

  // Auto Profile Checks
  if (profile === null) throw new Error('NO_AUTO_PROFILE')
  if (!profile.csrfToken) throw new Error('NO_CSRF_TOKEN')

  return autoKeepAlive({
    accessToken: transmitSession.data.access_token,
    csrfToken: profile.csrfToken,
  })
}

export async function autoLogout(args: {
  accessToken: string
  csrfToken: string
}): Promise<void> {
  const { accessToken, csrfToken } = args
  const response = await fetch(LOGOUT_ENDPOINT, {
    method: 'POST',
    headers: {
      ...SHARED_API_HEADERS,
      Authorization: `Bearer ${accessToken}`,
      csrftoken: csrfToken,
    },
  })

  if (!response.ok) throw new Error('LOGOUT_API_ERROR')
}

export async function autoLogoutWithTransmit(
  transmitSession: Session | null,
  profile: AutoProfile | null,
): Promise<void> {
  // Transmit Checks
  if (
    transmitSession === null ||
    transmitSession.status !== 'Authenticated' ||
    transmitSession.data === null
  )
    throw new Error('NOT_TRANSMIT_AUTHENTICATED')
  if (!hasAutoRelationship(transmitSession))
    throw new Error('NO_AUTO_RELATIONSHIP')

  // Auto Profile Checks
  if (profile === null) throw new Error('NO_AUTO_PROFILE')
  if (!profile.csrfToken) throw new Error('NO_CSRF_TOKEN')

  return autoLogout({
    accessToken: transmitSession.data.access_token,
    csrfToken: profile.csrfToken,
  })
}

async function autoLogin(args: {
  accessToken: string
  customerId: string
}): Promise<AutoProfile> {
  const { accessToken, customerId } = args
  const response = await fetch(LOGIN_ENDPOINT, {
    method: 'POST',
    headers: {
      ...SHARED_API_HEADERS,
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ customerId }),
  })

  if (!response.ok) throw new Error('LOGIN_API_ERROR')

  const json = await response.json()

  // Throw error if response does not have profile property
  const hasProfile = !!json.profile
  if (!hasProfile) throw new Error('LOGIN_API_ERROR')

  return json.profile
}

export async function autoLoginWithTransmit(
  transmitSession: Session | null,
): Promise<AutoProfile> {
  if (
    transmitSession === null ||
    transmitSession.status !== 'Authenticated' ||
    transmitSession.data === null
  )
    throw new Error('NOT_TRANSMIT_AUTHENTICATED')
  if (!hasAutoRelationship(transmitSession))
    throw new Error('NO_AUTO_RELATIONSHIP')
  if (!hasAutoProfileId(transmitSession)) throw new Error('NO_PROFILE_ID')

  const { access_token: accessToken, aaosId: customerId } = transmitSession.data

  return autoLogin({ accessToken, customerId })
}
