import { ParsedQuery } from 'query-string'
import { useState, useEffect, useMemo } from 'react'

import {
  size,
  compose,
  attemptOr,
  castArray,
  getJSONLocalStorage,
  setJSONLocalStorage,
  constant,
  mapValues,
} from '@ally/utilitarian'

import { LDFlagSet } from 'launchdarkly-js-client-sdk'
import {
  Route,
  Routes,
  useBootstrap,
  BootstrapServices,
  isAuthenticated,
  useSession,
} from '../providers'
import log from '../whisper'
import { track, TrackingEvent } from '../tracking'
import { CDN_URL, env, devRoutesStorageKey, CDN_DEV_URL } from '../constants'

export const shouldAddDevRoutes = (): boolean => {
  return env.isDevLike || env.isQA
}
const getParsedDevRouteQuery = (x: string | null | void): Routes => {
  return attemptOr({}, () => JSON.parse(x ? decodeURIComponent(x) : '{}'))
}

const getFederatedRouteFlags = (flags: LDFlagSet): LDFlagSet => {
  return Object.fromEntries(
    Object.entries(flags).filter(([name]) =>
      name.startsWith('ally-next-remote-'),
    ),
  )
}

const addDevRouteFlag = (routes: Routes): Routes => {
  return Object.entries(routes).reduce((acc: Routes, [key, value]) => {
    acc[key] = { ...value, isDevRoute: true }
    return acc
  }, {})
}

/**
 * Grabs the stored dev routes and routes from the query parameter `devRoutes`
 * and combines them together into an aggregate set of dev routes.
 *
 * NOTE: This is for DEVELOPMENT purposes only and will only execute in
 * localhost/dev environments.
 */
function getDevRoutes(query: ParsedQuery): Routes {
  const fromQuery = compose(
    addDevRouteFlag,
    getParsedDevRouteQuery,
    devRoutes => devRoutes[0],
    castArray,
  )(query?.devRoutes)

  const fromStorage = getJSONLocalStorage<Routes>(devRoutesStorageKey) || {}
  const aggregate = {
    ...fromStorage,
    ...fromQuery,
  }

  setJSONLocalStorage(devRoutesStorageKey, aggregate)
  return aggregate
}

/**
 * Given some dev and production routes, will combine the two into a unified
 * schema with the following semantics:
 *
 * - Dev routes are spread on top of production ones if the current environment
 *   is localhost, dev or QA.
 * - If the route is flagged `isDevRoute`, it will only be used in dev envs.
 * - If the route is disabled, it will be ignored.
 */
function combineRoutes({ dev, routes }: Record<string, Routes>): Routes {
  const result: Routes = {}
  const overrides = shouldAddDevRoutes() ? dev : {}

  Object.keys({ ...routes, ...overrides }).forEach(k => {
    const schema = {
      ...(routes[k] || {}),
      ...(overrides[k] || {}),
    }

    if (schema.isDevRoute && !shouldAddDevRoutes()) return
    if (schema.enabled !== false) result[k] = schema
  })

  return result
}

function handleLDRouteFailure(): void {
  track(TrackingEvent.LDRouteFailure)

  log.error({
    message:
      'Failed to retrieve routes from LaunchDarkly. ' +
      'Falling back to static routes.',
  })
}

function isRelativeURL(url: string): boolean {
  const r = new RegExp('^(?:[a-z+]+:)?//', 'i')
  return !r.test(url)
}

function sanitizeRouteSource(route: Route): Route {
  // Return route as is for non-relative sources
  if (!isRelativeURL(route.source)) return route
  // Use DEV CDN if it is a dev route
  const baseURL = !route.isDevRoute ? CDN_URL : CDN_DEV_URL
  return {
    ...route,
    source: `${baseURL}${route.source}`,
  }
}

/**
 * Gets all flags from LD that start with `ally-next-remote-`. If no flags are
 * available, then the static `fallbackRoutes` fetch from the server are used.
 */
async function getRouteSchema({
  query,
  featureFlags,
  fallbackRoutes,
}: BootstrapServices): Promise<Routes> {
  const dev = shouldAddDevRoutes() ? getDevRoutes(query) : {}
  const flags = featureFlags.routing.client.allFlags() ?? {}

  // Get variation reason for the SSO remote and send it to LR
  // We are doing this to figure out my "old" variations of remotes
  // are being served to customers even though they are not listed in
  // LD targeting rules
  const ssoVariationDetail = featureFlags.routing.client.variationDetail(
    'ally-next-remote-sso',
    { enabled: false },
  )
  track('ally-next-host-sso-variation-detail', {
    ...ssoVariationDetail.reason,
    value: ssoVariationDetail.value.branch ?? 'unknown',
  })

  const hasLDRoutes = size(flags) > 0
  if (!hasLDRoutes) handleLDRouteFailure()

  const schema = hasLDRoutes ? flags : await fallbackRoutes
  const routes = getFederatedRouteFlags(schema)
  const combined = combineRoutes({ dev, routes })

  // Add CDN added to source if enabled
  const useCDN = featureFlags.bank.client.variation(
    'FF_ally-next-remotes-from-cdn',
    true,
  )
  const withCDN = useCDN ? mapValues(combined, sanitizeRouteSource) : combined

  log.info({
    message: ['[REMOTES] Route Schema:', withCDN],
  })

  return withCDN
}

export function filterRemotes(predicate: (schema: Route) => boolean) {
  return (remotes: Routes): Routes => {
    return Object.entries(remotes).reduce((acc, [remoteKey, remoteSchema]) => {
      const includeRemote = predicate(remoteSchema)

      if (!includeRemote) return acc

      return {
        ...acc,
        [remoteKey]: remoteSchema,
      }
    }, {})
  }
}

/**
 * Keeps track of remote routes.
 * If the session object is updated, we need to re-evaluate the feature flags,
 * since they can be different on a per-user basis.
 */
export function useRemotes(filterFn?: (schema: Route) => boolean): Routes {
  const [remotes, setRemotes] = useState({})
  const filteredRemotes = useMemo(
    () => filterRemotes(filterFn ?? constant(true))(remotes),
    [filterFn, remotes],
  )
  const session = useSession()
  const hasAuthSession = isAuthenticated(session)
  const { services } = useBootstrap()

  useEffect(() => {
    if (services) getRouteSchema(services).then(setRemotes)
  }, [hasAuthSession, services])

  return filteredRemotes
}
