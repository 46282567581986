import { once } from '@ally/utilitarian'
import { BehaviorSubject, combineLatest, Observable } from 'rxjs'
import { FederatedAppStats, FederationStats } from '@ally-financial/next-core'
import { toProxyObject } from '../toProxyObject'

export const federatedHeaderStats = new BehaviorSubject<FederatedAppStats>({})
export const federatedContentStats = new BehaviorSubject<FederatedAppStats>({})
export const federatedDebugStats = new BehaviorSubject<FederatedAppStats>({})

export class FederationStatsService {
  private headerStats: BehaviorSubject<FederatedAppStats>

  private contentStats: BehaviorSubject<FederatedAppStats>

  private debugStats: BehaviorSubject<FederatedAppStats>

  readonly state$: Observable<FederationStats>

  constructor(
    federatedHeader: BehaviorSubject<FederatedAppStats>,
    federatedContent: BehaviorSubject<FederatedAppStats>,
    federatedDebug: BehaviorSubject<FederatedAppStats>,
  ) {
    this.headerStats = federatedHeader
    this.contentStats = federatedContent
    this.debugStats = federatedDebug

    this.state$ = combineLatest({
      header: this.headerStats,
      content: this.contentStats,
      debug: this.debugStats,
    })
  }

  getState(): FederationStats {
    return {
      header: { ...this.headerStats.getValue() },
      content: { ...this.contentStats.getValue() },
      debug: { ...this.debugStats.getValue() },
    }
  }
}

export default once(() =>
  toProxyObject(
    new FederationStatsService(
      federatedHeaderStats,
      federatedContentStats,
      federatedDebugStats,
    ),
  ),
)
