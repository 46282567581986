import { useMemo } from 'react'
import { FeatureFlagClientName } from '@ally-financial/next-core'
import { LoadState } from '@ally/use-data-domain'

import { useLRRegister } from './use-lr-register'
import { useLDRegister } from './use-ld-register'
import { useDTRegister } from './use-dt-register'

export interface UserRegistrationStates {
  ld: Record<FeatureFlagClientName, LoadState>
  lr: LoadState
  dt: LoadState
}

/**
 * Performs all "third-party" user registration tasks such as identifying the
 * user with LaunchDarkly and LogRocket.
 */
export function useRegisterUser(): UserRegistrationStates {
  const ld = useLDRegister()
  const lr = useLRRegister()
  const dt = useDTRegister()

  return useMemo(() => ({ ld, lr, dt }), [ld, lr, dt])
}
